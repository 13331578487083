import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { skyBlue, white } from "../../../../../constants/colors"
import Block from "../../../Block"
import InnerBlock from "../../../InnerBlock"
import Part from "../../../Part"
import Subheading from "../../../Subheading"
import LargeTitle from "../../../LargeTitle"
import Description from "../../../Description"
import BorderedButton from "../../../../button/BorderedButton"
import ColorWrapper from "../../../ColorWrapper"
import { smallMobile, tablet } from "../../../../../constants/screenSizes"
import IllustrationRipple from "../../../../../../svg/illustration-ripple.inline.svg"

const Container = styled.div``

const EnquiryFormLink = styled.u`
  cursor: pointer;
`

const IllustrationRippleContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 182px;
  pointer-events: none;

  @media (max-width: ${tablet}px) {
    height: 129px;
  }

  @media (max-width: ${smallMobile}px) {
    height: 91px;
  }
`

const IllustrationBackground = styled(IllustrationRipple)`
  width: 664px;
  height: 182px;

  @media (max-width: ${tablet}px) {
    width: 471px;
    height: 129px;
  }

  @media (max-width: ${smallMobile}px) {
    width: 332px;
    height: 91px;
  }
`

interface Props {
  description: React.ReactNode
}

const LPBookCallCTABlock: FC<Props> = ({ description }) => {
  return (
    <ColorWrapper background={skyBlue}>
      <Block>
        <InnerBlock>
          <Part width="70%">
            <Subheading isBordered borderColor={white} color={white}>
              Got a question?
            </Subheading>
            <LargeTitle margin="25px 0 10px 0" color={white}>
              We’re here to support you through every step of the process.
            </LargeTitle>
            <Description color={white}>{description}</Description>
          </Part>
          <Part width="218px" padding="65px 0 0 0" wrapPadding="40px 0 0 0">
            <BorderedButton
              to="https://meetings.hubspot.com/jessica-burns"
              icon="arrow right"
              target="_blank"
            >
              Book A Call
            </BorderedButton>
          </Part>
        </InnerBlock>
      </Block>
      <IllustrationRippleContainer>
        <IllustrationBackground />
      </IllustrationRippleContainer>
    </ColorWrapper>
  )
}

export default LPBookCallCTABlock
